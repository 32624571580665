<template>
  <div class="about">
    <h1>Change your password</h1>
    <div class="">
      <b-alert
        show
        variant="danger"
        dismissible
        v-for="(error, idx) in errors"
        :key="idx"
        >{{ error }}</b-alert
      >
      <b-form @submit="onSubmit">
        <b-form-group
          id="input-group-1"
          label="Old Password:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.OldPassword"
            type="password"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="New password:"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="form.NewPassword"
            type="password"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Confirm new password"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="form.ConfirmPassword"
            type="password"
            required
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary" :disabled="!isFormValid">
          Update</b-button
        >
      </b-form>
    </div>
  </div>
</template>


<script>
import accountApi from "@/api/accountApi";

export default {
  name: "changePassword",
  data() {
    return {
      errors: [],
      form: {
        OldPassword: "",
        NewPassword: "",
        ConfirmPassword: "",
      },
    };
  },
  components: {},
  created() {},
  mounted() {},
  computed: {
    isFormValid() {
      return (
        this.form.OldPassword.length > 0 &&
        this.form.NewPassword.length > 0 &&
        this.form.NewPassword == this.form.ConfirmPassword
      );
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      accountApi
        .changePassword(this.form)
        .then((response) => {
          this.errors = [];

          this.form.OldPassword = "";
          this.form.NewPassword = "";
          this.form.ConfirmPassword = "";
          if (response == true) {
            this.errors.push("Change password successfully");
          } else {
            this.errors.push("Old password is incorrect");
          }
        })
        .catch((error) => {
          this.loading = false;
          this.errors = [];
          this.errors.push(error.message);
        });
    },
  },
};
</script>
